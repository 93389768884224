export const ModuleConfig = {
    routerPrefix: 'tasks',
    loadOrder: 20,
    moduleName: 'Tasks',
};

export function init(context, router) {
    context.addRoute({
        path: '/tasks',
        name: 'tasks',
        component: () => import(/* webpackChunkName: "invoice" */ './views/Tasks.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'list',
                name: 'task.list',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/List.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                path: 'view/:id',
                name: 'task.view',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/View.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                path: 'new',
                name: 'task.new',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/New.vue'),
                meta: {
                    auth: true,
                },
            },
        ],
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
