export const ModuleConfig = {
    routerPrefix: 'projects',
    loadOrder: 20,
    moduleName: 'Projects',
};

export function init(context, router) {
    context.addRoute({
        path: '/projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "invoice" */ './views/Projects.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'list',
                name: 'project.list',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/List.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                path: 'view/:id',
                name: 'project.view',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/View.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                path: 'members/:id',
                name: 'project.members',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/Members.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                path: 'new',
                name: 'project.new',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/New.vue'),
                meta: {
                    auth: true,
                },
            },
        ],
    });

    context.addNavbarEntry({
        label: 'navigation.projects',
        to: {
            name: 'project.list',
        },
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
