import axios from '@/config/app';
import SettingsService from '@/services/settings.service';
import Store from '@/store';
import i18n from '@/i18n';
import moment from 'moment';

export default class AccountService extends SettingsService {
    getItemRequestUri() {
        return `/api/v1/auth/me`;
    }

    getAll() {
        let user = Store.getters['user/user'];
        if (Object.keys(user).length) {
            Promise.resolve().then(() => {
                return {
                    data: {
                        id: user.id,
                        password: user.password,
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        full_name: `${user.first_name} ${user.last_name}`,
                        user_language: user.user_language,
                    },
                };
            });
        }

        return axios.get(`api/v1/users/${user.id}`, { ignoreCancel: true }).then(({ data }) => {
            const values = data.data.attributes;
            return {
                id: values.id,
                password: values.password,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                full_name: `${values.first_name} ${values.last_name}`,
                user_language: values.user_language,
            };
        });
    }

    save(data) {
        i18n.locale = data.user_language;
        moment.locale(data.user_language);
        return axios.put(`api/v1/users/${data.id}`, data).then(({ data }) => {
            const res = data.data.attributes;
            return {
                data: {
                    attributes: {
                        id: res.id,
                        password: res.password,
                        email: res.email,
                        first_name: res.first_name,
                        last_name: res.last_name,
                        full_name: `${res.first_name} ${res.last_name}`,
                        user_language: res.user_language,
                    },
                },
            };
        });
    }
}
