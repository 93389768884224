import axios from '@/config/app';
import SettingsService from '@/services/settings.service';
import Store from '@/store';
import ApiService from '@/services/api';

export default class AvatarService extends SettingsService {
    constructor() {
        super();
        this.api = new ApiService();
    }

    getItemRequestUri() {
        return `/api/v1/auth/me`;
    }

    getAll() {
        let user = Store.getters['user/user'];

        return axios.get(`api/v1/users/${user.id}`, { ignoreCancel: true }).then(({ data }) => {
            const values = data.data.attributes;
            const company = Store.getters['company/company'];
            this.api.userRoleAccess(values, company);
            localStorage.setItem('user', JSON.stringify(values));
            return {
                id: values.id,
                password: values.password,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                user_language: values.user_language,
                avatar_url: values.avatar_url,
            };
        });
    }

    save(data) {
        return axios.post('api/v1/avatars', data);
    }

    update(data, id) {
        return axios.put(`api/v1/avatars/${id}`, data);
    }

    destroy(id) {
        return axios.delete(`api/v1/avatars/${id}`);
    }
}
